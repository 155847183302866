<template>
  <div>
    <span class="text-xs py-2 px-4 gap-2 text-center flex items-center whitespace-nowrap font-bold rounded-full text-white bg-yellow-400 text-white-text" v-if="item.sequence_status === 'hold'">
      <pause-icon class="field__icon" />
      Sequence paused
    </span>
    <span class="text-xs py-2 px-4 gap-2 text-center flex items-center whitespace-nowrap font-bold rounded-full text-white bg-red-400 text-white-text" v-else-if="item.sequence_status === 'exit'">
      <cancel-icon class="field__icon" />
      Sequence cancelled
    </span>
    <span class="text-xs py-2 px-4 gap-2 text-center flex items-center whitespace-nowrap font-bold rounded-full text-white bg-blue-600 text-white-text" v-else>
        <emailIcon stroke="var(--theme-color-white-text)"/>
        Next email due in {{ item.due_days }} days 
    </span>
  </div>
</template>

<script>
const emailIcon = () => import("@/assets/icons/profile-view/wEmail.svg");
import pauseIcon from "@/assets/icons/dashboard/pause.svg";
import cancelIcon from "@/assets/icons/dashboard/cancel.svg";

export default {
  name: "badge",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    emailIcon,
    pauseIcon,
    cancelIcon,
  }
};
</script>

<style lang="scss">
.field__icon {
      flex-shrink: 0;
      @apply w-5 h-5 fill-current;
}
</style>
